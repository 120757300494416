import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosError, AxiosInstance } from "axios";
import { useRouter } from "next/navigation";
import { createContext, ReactNode, useMemo } from "react";

import { env } from "../env";

type ApiClientContextType = {
  apiClient: AxiosInstance;
};

export const ApiClientContext = createContext<ApiClientContextType | undefined>(
  undefined
);

type ApiClientProviderProps = {
  children: ReactNode;
};

export function ApiClientProvider({ children }: ApiClientProviderProps) {
  const router = useRouter();
  const { getAccessTokenSilently } = useAuth0();

  const apiClient = useMemo(
    () =>
      axios.create({
        baseURL: env.BACKEND_URL,
        timeout: 120_000,
      }),
    []
  );

  apiClient.interceptors.request.use(async (config) => {
    try {
      const token = await getAccessTokenSilently();

      config.headers.Authorization = `Bearer ${token}`;
    } catch {
      router.push("/login");
    }

    return config;
  });

  apiClient.interceptors.response.use(
    (res) => res,
    async (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          router.push("/login");
        }

        const message = error.response?.data?.error;

        if (typeof message === "string") {
          throw new AxiosError(
            message,
            error.code,
            error.config,
            error.request,
            error.response
          );
        }
      }
      throw error;
    }
  );

  return (
    <ApiClientContext.Provider value={{ apiClient }}>
      {children}
    </ApiClientContext.Provider>
  );
}
