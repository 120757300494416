"use client";

import { Auth0Provider } from "@auth0/auth0-react";
import { NextUIProvider } from "@nextui-org/system";
import { ReactNode } from "react";
import { Toaster } from "react-hot-toast";

import { StateProvider } from "@revv/state";
import { ConfirmProvider } from "@revv/ui";

import { env } from "@/env";
import { useApiClient } from "@/hooks/useApiClient";

import { ApiClientProvider } from "./ApiClientProvider";

const toasterStyle = {
  marginTop: "1.5rem",
  padding: "0.85rem",
  borderRadius: "0.5rem",
};

type ProvidersProps = {
  children: ReactNode;
};

export function Providers({ children }: ProvidersProps) {
  const redirectUri =
    typeof window === "undefined" ? "" : window.location.origin;

  return (
    <Auth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: env.AUTH0_AUDIENCE,
        scope: "openid profile email offline_access",
      }}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <ApiClientProvider>
        <NextUIProvider>
          <ConfirmProvider>
            <RevvStateProvider>
              {children}
              <Toaster
                position="top-right"
                toastOptions={{ style: toasterStyle }}
              />
            </RevvStateProvider>
          </ConfirmProvider>
        </NextUIProvider>
      </ApiClientProvider>
    </Auth0Provider>
  );
}

type RevvStateProviderProps = {
  children: ReactNode;
};

function RevvStateProvider({ children }: RevvStateProviderProps) {
  const apiClient = useApiClient();

  return (
    <StateProvider apiClient={apiClient}>
      <>{children}</>
    </StateProvider>
  );
}
